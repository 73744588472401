export default [
    {
        "fa": "fas fa-podcast",
        "value": "Your Body Show Website",
        "linkTo": "https://yourbodyshow.com/"
    },
    {
        "fa": "fas fa-play-circle",
        "value": "Latest Episode - Breast Health 101",
        "linkTo": "https://yourbodyshow.com/breast-health-101-breast-density-regular-screening-advancements-in-technology"
    },
    {
        "fa": "fas fa-podcast",
        "value": "Listen on Libsyn",
        "linkTo": "https://radnet.libsyn.com/radiopublic"
    },
    {
        "fa": "fab fa-spotify",
        "value": "Listen on Spotify",
        "linkTo": "https://open.spotify.com/show/66eJz452ZdvathddHF0Sdd"
    },
    {
        "fa": "fab fa-apple",
        "value": "Listen on Apple Podcasts",
        "linkTo": "https://podcasts.apple.com/us/podcast/your-body-show/id1541229076"
    },
    {
        "fa": "fas fa-music",
        "value": "Listen on Deezer",
        "linkTo": "https://www.deezer.com/us/show/1995252"
    },
    {
        "fa": "fas fa-tv",
        "value": "Watch on RadNet TV",
        "linkTo": "https://www.radnet.tv/categories/your-body-podcast"
    },
    {
        "fa": "fas fa-rss",
        "value": "RSS Feed",
        "linkTo": "https://yourbodyshow.com/rss"
    }
]